import { header } from '../../styles/colors';
import { BreakPoints, CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	blueCircle: {
		alignItems: 'center',
		backgroundColor: '#046CB6',
		borderRadius: 75,
		diplay: 'flex',
		height: 150,
		justifyContent: 'center',
		width: 150,
	},
	container: {
		paddingTop: 20,
		position: 'relative',
		width: 800,
		[BreakPoints.tabletWidth]: {
			width: 700,
		},
		[BreakPoints.tabletCompactWidth]: {
			width: 600,
		},
	},
	editor: {
		background: '#F9F9F9',
		border: '1px solid #ccc',
		height: 100,
	},
	editorSmall: {
		background: '#F9F9F9',
		border: '1px solid #ccc',
		height: 60,
	},
	footer: {
		display: 'flex',
		marginTop: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	promptText: {
		color: header,
		fontSize: 14,
		margin: '18px 0',
	},
	promptTextWhite: {
		color: '#fff',
		fontSize: 14,
		margin: '18px 0',
		textAlign: 'left',
	},
});

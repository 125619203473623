import { IAppToastMessage, ToasterViewModel } from '../../../viewmodels/AppViewModels';
import { StandardToastMessage } from './StandardToastMessage';
import './styles.less';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
	toasterViewModel: ToasterViewModel<IAppToastMessage>;
}

export const Toaster: React.FC<IProps> = observer((props: IProps) => {
	const currentToastMessage = props?.toasterViewModel?.currentToastMessage;
	const getContent = () => {
		switch (currentToastMessage.type) {
			case 'successMessage':
			case 'textMessage':
			case 'errorMessage': {
				return <StandardToastMessage type={currentToastMessage.type} toastMessage={currentToastMessage} />;
			}
			case 'custom': {
				return props.toasterViewModel.currentToastMessage.customContent;
			}
			default: {
				break;
			}
		}
	};

	return (
		<div className={`toaster ${currentToastMessage ? 'showing-toast-message' : ''} ${props.className || ''}`}>
			{!!currentToastMessage && (
				<div className={`toaster-content ${currentToastMessage.type === 'custom' ? 'custom-toaster-content' : ''}`}>
					{getContent()}
				</div>
			)}
		</div>
	);
});

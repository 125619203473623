import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const LogoIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	const fill = fillColor || '#fff';

	return (
		<SvgIcon className={`logo-icon ${className ?? ''}`} width={169} height={31} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M76 0V31H76.8853V0.889254H101.11V1.85242H80.6497V4.59435H102V3.70535H81.5353V2.74167H101.996V0H76Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M74.1203 0V30.1101H72.9561V0.00104175H61.9103L54.7278 21.9294L47.5452 0.00104175H44.461L53.6884 27.8951H55.7107L64.1318 2.72106H65.5164L56.0469 29.8509H53.2201L43.2474 0.00104175H21L21.0129 29.1071H21.8928V3.23907L21.8833 0.89096H42.6161L52.5889 30.7409H56.6694L66.7596 1.83114H63.5011L55.08 27.0054H54.3218L45.6826 0.89096H46.9104L54.7278 24.7583L62.5454 0.89096H72.0767V31H75V0H74.1203Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 0V31H20.7849H36.973V28.288H23.1234V2.80292H36.122V3.79607H24.349V13.3325H35.5678V14.2917H24.349V27.2428H36.962V26.3508H25.227V15.1843H36.446V12.4404H25.227V4.68842H37V1.91057H22.2454V29.1806H36.095V30.1074H20.7849H0.877985V0.892086H1.92042V29.1806H15.0321V26.3508H4.77355V0.00261149H3.89556V27.2428H14.1541V28.288H2.7984V0H0Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M122 25H107L107.315 24.1244L120.694 24.1247L114.568 8.33498L109.172 22.2154H118.616L118.962 23.091H107.865L114.568 6L122 25Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M152.861 0H149.325H146.699H128.004V2.74278H148.194V3.70659H128V4.5962H149.067V1.85316H128.878V0.889352H146.699H149.325H152.861H168.1V1.81359H154.315V29H169V26.2304H157.283V15.7743H168.449V13.0388H157.283V4.63525H168.962V3.74564H156.409V13.9284H167.575V14.8847H156.409V27.12H168.126V28.1104H155.189V2.7032H168.974V0H152.861Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M112.443 0L100.752 30.0209H93.9504V7.19977H91.0369V30.0209H89.8767V7.1927H89V30.9159H91.9135V8.09454H93.0737V30.9159H101.347L113.038 0.894767H115.694L126.367 27.7778H125.037L115.333 2.56463H113.438L102.493 31H105.73L107.147 27.2497H121.43L122.815 30.9183L135.218 30.9159H140V7.19977H137.087V28.1149H135.927V7.1927H135.05V29.0096H137.963V8.09454H139.123V30.0209H128.183V30.023L123.417 30.0235L122.031 26.3546H106.545L105.129 30.1052H103.78L114.035 3.4594H114.735L124.44 28.6726H127.668L116.284 0H112.443Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M152.013 2V27.7699L152 31H169V30.1133H152.899L152.909 2H152.013Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};

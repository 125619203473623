import { IAppToastMessage, ToastMessageType } from '../../../../viewmodels/AppViewModels';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps {
	className?: string;
	toastMessage: IAppToastMessage;
	type?: Omit<ToastMessageType, 'custom'>;
}

export const StandardToastMessage: React.ComponentClass<IProps> = withRouter(
	(props: IProps & RouteComponentProps<any>) => {
		const { className, toastMessage, type } = props;

		const onLinkClicked = () => {
			if (toastMessage.onLinkClicked) {
				toastMessage.onLinkClicked(props);
			}
		};

		let colorClass: object;
		switch (type) {
			case 'successMessage':
				colorClass = styleSheet.success;
				break;
			case 'errorMessage':
				colorClass = styleSheet.error;
				break;
			case 'textMessage':
				colorClass = styleSheet.standard;
				break;
			default:
				break;
		}

		const iconClass = `${css(styleSheet.icon)} success-toast-message-icon`;

		return (
			// @ts-ignore
			<div className={`${css(styleSheet.container, colorClass)} success-toast-message ${className || ''}`}>
				{type === 'errorMessage' && <DangerIcon className={iconClass} />}
				{type === 'successMessage' && <SuccessCheckIcon className={iconClass} />}
				<span className={`${css(styleSheet.text)} success-toast-message-text`}>{toastMessage.message}</span>
				{!!toastMessage.linkTitle && (
					<button className={`${css(styleSheet.link)} success-toast-message-link`} onClick={onLinkClicked}>
						{toastMessage.linkTitle}
					</button>
				)}
			</div>
		);
	}
);

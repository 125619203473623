import { brandPrimaryHover, success, successTint, urgentDate } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		borderRadius: 3,
		display: 'flex',
		padding: '14px 20px 14px 10px',
	},
	error: {
		background: '#FFE7EA',
		border: `1px solid ${urgentDate}`,
	},
	icon: {
		marginRight: 8,
	},
	link: {
		color: brandPrimaryHover,
		fontSize: 14,
		marginLeft: 20,
	},
	standard: {
		// correcting for no icon
		paddingLeft: 20,
	},
	success: {
		background: successTint,
		border: `1px solid ${success}`,
	},
	text: {
		fontSize: 14,
	},
});
